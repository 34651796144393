// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MovementsList from './components/MovementsList';
import AccountSelector from './components/AccountSelector';
import Login from './components/Login';

const App = () => {
    const [selectedAccount, setSelectedAccount] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Controlla lo stato di autenticazione nel local storage
        const auth = localStorage.getItem('isAuthenticated');
        if (auth === 'true') {
            setIsAuthenticated(true);
        }
        // Controlla l'account selezionato nel local storage
        const account = localStorage.getItem('selectedAccount');
        if (account) {
            setSelectedAccount(account);
        }
    }, []);

    const handleSelectAccount = (account) => {
        setSelectedAccount(account);
        localStorage.setItem('selectedAccount', account);
    };

    const handleLogin = () => {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        setSelectedAccount('');
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('selectedAccount');
    };

    const handleSelectNewAccount = () => {
        setSelectedAccount('');
        localStorage.removeItem('selectedAccount');
    };

    if (!isAuthenticated) {
        return <Login onLogin={handleLogin} />;
    }

    if (!selectedAccount) {
        return <AccountSelector onSelectAccount={handleSelectAccount} />;
    }

    return (
        <Router>
            <div>
                <div className="flex justify-between items-center p-4 bg-gray-200">
                    <button onClick={handleLogout} className="bg-red-500 text-white p-2 rounded hover:bg-red-600">Logout</button>
                    <button onClick={handleSelectNewAccount} className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Seleziona Conto</button>
                </div>
                <Routes>
                    <Route path="/" element={<MovementsList account={selectedAccount} />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
