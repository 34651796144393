// src/components/Login.js
import React, { useState } from 'react';
import logo from '../assets/logo.png'; // Importa il logo

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logica di autenticazione simulata
        if (username === 'Max' && password === 'Borgomassano') {
            onLogin();
        } else {
            alert('Username o password errati');
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md text-center w-full max-w-sm">
                <img src={logo} alt="Logo" className="mx-auto mb-6   h-24" /> {/* Aggiungi il logo */}
                <h1 className="text-3xl font-bold mb-6 text-gray-700">Login</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block mb-2 text-gray-600">Username</label>
                        <input
                            type="text"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="border p-2 w-full rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 text-gray-600">Password</label>
                        <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="border p-2 w-full rounded"
                            required
                        />
                    </div>
                    <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
