// src/components/AccountSelector.js
import React from 'react';

const AccountSelector = ({ onSelectAccount }) => {

    const handleSelect = (account) => {
        onSelectAccount(account);
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md text-center w-full max-w-sm">
                <h1 className="text-3xl font-bold mb-6 text-gray-700">Seleziona il conto</h1>
                <div className="flex flex-col space-y-4">
                    <button
                        type="button"
                        className="p-3 rounded bg-blue-500 text-white hover:bg-blue-600 transition duration-200"
                        onClick={() => handleSelect('personale')}
                    >
                        Personale Casa
                    </button>
                    <button
                        type="button"
                        className="p-3 rounded bg-green-500 text-white hover:bg-green-600 transition duration-200"
                        onClick={() => handleSelect('caveau')}
                    >
                        Brand Group
                    </button>
                    <button
                        type="button"
                        className="p-3 rounded bg-purple-500 text-white hover:bg-purple-600 transition duration-200"
                        onClick={() => handleSelect('conto3')}
                    >
                        Residenza Ambrogi
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccountSelector;
